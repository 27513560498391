import React, { Component } from 'react';
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { coyWithoutShadows as highlightTheme } from 'react-syntax-highlighter/dist/esm/styles/prism'
import gfm from 'remark-gfm';

export default class SideMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.renderers = {
            code: ({ language, value }) => {
                return <SyntaxHighlighter style={highlightTheme} language={language} children={value} showLineNumbers={false} />;
            },
            link: ({ children, href }) => {
                var isInternalLink = this.props.isInternalLink(href);
                return <React.Fragment>
                           {isInternalLink && <a href={this.props.getAbsolutePageUrl(href)} data-path={href} onClick={this.props.handleInternalLinkClick} className="internal-docs-link">{children}</a>}
                           {!isInternalLink && <a href={href}>{children}</a>}
                       </React.Fragment>;
            },
        }
    }

    render() {
        
        return (
            <div className="sidebar-sticky">
                <ReactMarkdown plugins={[gfm]} source={this.props.markdown} allowDangerousHtml={true} renderers={this.renderers} />
            </div>
        );
    }
}
