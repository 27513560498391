import React, { Component } from 'react';
import ReactMarkdown from "react-markdown";
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { coyWithoutShadows as highlightTheme } from 'react-syntax-highlighter/dist/esm/styles/prism'
import gfm from 'remark-gfm';
import SideMenu from './components/SideMenu';
import {
    Alert,
    Container,
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
    Row,
    Col,
    Spinner,
    Button
} from 'reactstrap';

import './Docs.scss'

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);

        this.renderers = {
            code: ({ language, value }) => {
                return <SyntaxHighlighter style={highlightTheme} language={language} children={value} showLineNumbers={false} />
            },
            image: ({ alt, src }) => {
                return <img src={this.getImageUrl(src)} alt={alt} />;
            },
            text: ({ children }) => {
                if (children === "$DocStatus:Draft") {
                    return <Alert color="danger">
                        <h4 className="alert-heading">Document Status: Draft</h4>
                        <p>The content of this page is in draft status. This means it can change any number of times and at any time depending on any number of factors. It should be used as a guide only and not as absolute fact.</p>
                    </Alert>;
                }
                if (children === "$DocStatus:Review") {
                    return <Alert color="warning">
                        <h4 className="alert-heading">Document Status: Review</h4>
                        <p>The content of this page is in review status. This means it is unlikely to change drastically, but some smaller details may still be changed. It is safe to be more or less used as fact.</p>
                    </Alert>;
                }
                if (children === "$DocStatus:Final") {
                    return <Alert color="success">
                        <h4 className="alert-heading">Document Status: Final</h4>
                        <p>The content of this page is in final status. This means it's in it's last version and extremely unlikely to change any further. It is safe to use this content as fact.</p>
                    </Alert>;
                }
                return children;
            },
            link: ({ children, href }) => {
                var isInternalLink = this.isInternalLink(href);
                return <React.Fragment>
                    {isInternalLink && <a href={this.getRelativePageUrl(href)} data-path={href} onClick={this.handleInternalLinkClick} className="internal-docs-link">{children}</a>}
                    {!isInternalLink && <a href={href} className="external-docs-link">{children}</a>}
                </React.Fragment>;
            },
            heading: ({ level, children }) => {
                return <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className={`h${level + 1}`}>{children}</h1>
                </div>;
            }
        }

        document.title = "eBev Tech Docs";

        this.state = {
            loggedIn: false,
            profile: {},
            tokenId: null,
            repos: null,
            selectedRepo: { name: 'current', key: 'current' },
            selectedBranch: { name: '', key: '' },
            selectedVersion: { name: '', key: '' },
            selectedPath: 'home',
            pageContent: '',
            sidebarContent: ''
        };
    }

    handleInternalLinkClick = (e) => {
        e.preventDefault();
        var path = e.target.dataset.path.replace(".md", "");

        //console.log("Current Path:", this.state.selectedPath.split("/"));

        if (path.startsWith("/")) {
            // absolute
            path = path.replace(new RegExp("^[/]+"), "");
            console.log("showing doc:", path);
            this.showContent(path);
        } else {
            // relative to the current path
            var parts = this.state.selectedPath.split("/");
            var parentPath = parts.slice(0, parts.length - 1).join("/");
            console.log("parent path:", parentPath);
            this.showContent(parentPath + "/" + path);
        }
    }

    handleInternalAbsoluteLinkClick = (e) => {
        e.preventDefault();
        var path = e.target.dataset.path.replace(".md", "");
        this.showContent(path.trim("/"));
    }

    isInternalLink = (url) => {
        return !url.toLowerCase().startsWith("http");
    }

    getRelativePageUrl = (url) => {
        if (!this.isInternalLink(url)) {
            return url;
        }

        // 'absolute' url, where the root is the base docs path
        if (url.startsWith("/")) {
            return `/${this.state.selectedRepo.key}/${this.state.selectedBranch.key}/${this.state.selectedVersion.key}${url.replace(".md", "")}`;
        }

        var parts = this.state.selectedPath.split("/");
        var parentPath = parts.slice(0, parts.length - 1).join("/");

        return `/${this.state.selectedRepo.key}/${this.state.selectedBranch.key}/${this.state.selectedVersion.key}/${parentPath}/${url.replace(".md", "")}`;
    }

    getAbsolutePageUrl = (url) => {
        if (!this.isInternalLink(url)) {
            return url;
        }

        // 'absolute' url, where the root is the base docs path
        if (url.startsWith("/")) {
            return `/${this.state.selectedRepo.key}/${this.state.selectedBranch.key}/${this.state.selectedVersion.key}${url.replace(".md", "")}`;
        }

        //if (url.contains("./")) {
        //    // it's referencing a higher path.. need to factor this in at some point but probably not now
        //}

        return `/${this.state.selectedRepo.key}/${this.state.selectedBranch.key}/${this.state.selectedVersion.key}/${url.replace(".md", "")}`;
    }

    getImageUrl = (src) => {

        if (src.toLowerCase().startsWith("http")) {
            return src;
        }
        var s = src.replace(new RegExp("^[.]+"), "").replace(new RegExp("^[/]+"), "");
        var apiUrl = `/api/image/${this.state.selectedRepo.key}/${this.state.selectedBranch.key}/${this.state.selectedVersion.key}/${s}`;

        return apiUrl;
    }

    loginSuccess = (res) => {
        //console.log("login success: ", res);
        this.setState({ loggedIn: true, profile: res.profileObj, token: res.tokenId });

        var pathParts = window.location.pathname.split("/").filter(x => x != null && x != "");
        var defaultRepo = pathParts.length > 0 ? pathParts[0] : null;
        var defaultBranch = pathParts.length > 1 ? pathParts[1] : null;
        var defaultVersion = pathParts.length > 2 ? pathParts[2] : null;
        var defaultPath = pathParts.length > 3 ? pathParts.slice(3).join("/") : null;

        this.makeApiCall(`/api/repo/all`,
            result => {
                console.log("Repos Result: ", result);
                var repo = defaultRepo ? result.find(x => x.key == defaultRepo) : result[0];
                var branch = defaultBranch ? repo.branches.find(x => x.key == defaultBranch) : repo.branches[0];
                var version = defaultVersion ? branch.versions.find(x => x.key == defaultVersion) : branch.versions[0];
                var path = defaultPath || "home";
                this.setState({
                    repos: result,
                    selectedRepo: repo || {},
                    selectedBranch: branch || {},
                    selectedVersion: version || {}
                }, () => {
                    this.refreshSidebar();
                    this.showContent(path);
                });
            });
    }

    loginFailure = (res) => {
        console.log("login failure: ", res);
        this.setState({ loggedIn: false, profile: {} });
    }

    logoutSuccess = (res) => {
        console.log("logout success: ", res);
        this.setState({ loggedIn: false, profile: {} });
    }

    selectVersion = (item) => {
        console.log("You selected :", item);
        this.setState({ selectedVersion: item }, () => {
            this.refreshSidebar();
            this.showContent('home');
        });
    }

    selectRepo = (item) => {
        console.log("You selected Repo :", item);
        this.setState({ selectedRepo: item, selectedBranch: item.branches[0], selectedVersion: item.branches[0].versions[0] || {}
        }, () => {
            this.refreshSidebar();
            this.showContent('home');
        });
    }

    selectBranch = (item) => {
        console.log("You selected Branch :", item);
        this.setState({ selectedBranch: item, selectedVersion: item.versions[0] || {} }, () => {
            this.refreshSidebar();
            this.showContent('home');
        });
    }

    refreshSidebar = () => {
        this.makeApiCall(`/api/document/${this.state.selectedRepo.key}/${this.state.selectedBranch.key}/${this.state.selectedVersion.key}/sidebar`,
            result => {
                this.setState({ sidebarContent: result.content });
            });
    }

    showContent = (path) => {
        this.makeApiCall(`/api/document/${this.state.selectedRepo.key}/${this.state.selectedBranch.key}/${this.state.selectedVersion.key}/${path}`,
            result => {
                this.setState({ pageContent: result.content, selectedPath: path },
                    () => {
                        this.updatePageUrl();
                    });
            });
    }

    updatePageUrl = () => {
        const nextURL = window.location.origin + `/${this.state.selectedRepo.key}/${this.state.selectedBranch.key}/${this.state.selectedVersion.key}/${this.state.selectedPath}`;
        const nextTitle = 'eBev Tech Docs';
        const nextState = { additionalInformation: 'eBev Tech Docs' };
        window.history.pushState(nextState, nextTitle, nextURL);
    }

    makeApiCall = (url, callback) => {
        fetch(url, { method: 'get', credentials: 'same-origin', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.state.token}` } })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(callback)
            .catch(error => {
                console.log("Got an error :(", error);
            });
    }

    render() {

        const markdown = this.state.pageContent;
        const sidemenuMarkdown = this.state.sidebarContent;

        const hasContent = markdown != null && markdown !== "" && markdown.length > 0;

        // This needs to be based on something more than just a shitty email check. Maybe pull something else from the google profile, E.g. department?
        const isAdmin = this.state.profile != null && (this.state.profile.email === "jason@ebev.com" || this.state.profile.email === "matthew.cox@ebev.com" || this.state.profile.email === "susantha@ebev.com");

        return (
            <React.Fragment>
                {this.state.loggedIn &&
                    <Navbar color="dark" dark expand="md">
                        <NavbarBrand href="/">eBev Tech Docs</NavbarBrand>
                        <Collapse isOpen={true} navbar>
                            <Nav className="mr-auto" navbar>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret><strong>{(this.state.selectedRepo || {}).name}</strong></DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem header>Repository</DropdownItem>
                                        {this.state.repos != null && this.state.repos.map(item => <DropdownItem key={item.key} onClick={e => this.selectRepo(item)}>{item.name}</DropdownItem>)}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret><strong>{this.state.selectedBranch.name}</strong></DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem header>Branch</DropdownItem>
                                        {this.state.selectedRepo != null && this.state.selectedRepo.branches != null && this.state.selectedRepo.branches.map(item => <DropdownItem key={item.key} onClick={e => this.selectBranch(item)}>{item.name}</DropdownItem>)}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret><strong>{(this.state.selectedVersion || {}).name}</strong></DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem header>Version</DropdownItem>
                                        {this.state.selectedBranch != null && this.state.selectedBranch.versions != null && this.state.selectedBranch.versions.map(item => <DropdownItem key={item.key} onClick={e => this.selectVersion(item)}>{item.name}</DropdownItem>)}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                {isAdmin && <RefreshRepoButton repo={this.state.selectedRepo} makeApiCall={this.makeApiCall} />}
                            </Nav>
                            <NavbarText>{this.state.profile != null && <div className="nav-link"><img src={this.state.profile.imageUrl} style={{ maxHeight: '20px', borderRadius: '10px' }} /> <b>{this.state.profile.name}</b></div>}</NavbarText>
                            <NavbarText>
                                <GoogleLogout
                                    clientId="361017491672-rtn4bia336aucmtq120tvr37hg1lp51t.apps.googleusercontent.com"
                                    buttonText="Logout"
                                    onLogoutSuccess={this.logoutSuccess}
                                    render={renderProps => (<a onClick={renderProps.onClick} style={{ cursor: 'pointer' }} href="#">Sign out</a>)}>
                                </GoogleLogout>
                            </NavbarText>
                        </Collapse>
                    </Navbar>
                }
                <Container fluid={true}>
                    {this.state.loggedIn &&
                        <Row>
                            <Col className="sidebar-st">
                                <SideMenu markdown={sidemenuMarkdown} currentVersion={this.state.selectedVersion.key} getAbsolutePageUrl={this.getAbsolutePageUrl} handleInternalLinkClick={this.handleInternalAbsoluteLinkClick} isInternalLink={this.isInternalLink} />
                            </Col>
                            <Col className="body-content">
                                {hasContent && <ReactMarkdown plugins={[gfm]} source={markdown} allowDangerousHtml={true} renderers={this.renderers} />}
                                {!hasContent && <Alert color="warning">This page does not yet exist. Create <strong>/docs/{this.state.selectedPath}.md</strong> in this repo for it to appear here.</Alert>}
                            </Col>
                        </Row>
                    }
                    {!this.state.loggedIn && <React.Fragment>
                        <GoogleLogin
                            clientId="361017491672-rtn4bia336aucmtq120tvr37hg1lp51t.apps.googleusercontent.com"
                            buttonText="Login"
                            onSuccess={this.loginSuccess}
                            onFailure={this.loginFailure}
                            cookiePolicy={'single_host_origin'}
                            isSignedIn={true}
                        />
                    </React.Fragment>}
                </Container>
            </React.Fragment>
        );
    }
}

class RefreshRepoButton extends Component {
    constructor(props) {
        super(props);

        this.state = { isRefreshing: false };
    }

    updateRepo = () => {
        console.log("updating repo: ", this.props.repo.key);
        this.setState({ isRefreshing: true },
            () => {
                this.props.makeApiCall(`/api/document/update-all-repos`,
                    result => {
                        window.location.reload();
                    });
            });
    }

    render() {

        return (
            <React.Fragment>
                {this.state.isRefreshing && <Spinner color="light" />}
                {!this.state.isRefreshing && <Button outline color="secondary" size="sm" onClick={this.updateRepo}>Refresh {this.props.repo.name} from source</Button>}
            </React.Fragment>
        );
    }
}
